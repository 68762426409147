<template>
	<div>
		<base-data-table
			:title="$t('products')"
			:add="false"
			:actions="actions"
			:headers="headers"
			:provider="products"
			:fetch-function="onFetchProducts"
			translate
			bulkUpload
			@view="onViewProduct"
			@update="onUpdateBranch"
			@delete="onDeleteProduct"
			@status="onChangeStatus"
			@uploadBulkProduct="onBulkUpload"
			@uploadSingleProduct="onSingleUpload"
		>
			<template #search>
				<v-btn
					depressed
					height="48"
					color="primary"
					@click="onToggleSearch"
				>
					<v-icon v-if="search.isExpanded">mdi-close</v-icon>
					<v-icon v-else>mdi-filter</v-icon>
				</v-btn>
			</template>

			<template v-if="search.isExpanded" #extension>
				<div class="tw-bg-gray-200 tw-p-4 tw-mx-4 tw-rounded">
					<div class="tw-grid tw-gap-4 tw-grid-cols-3">
						<v-text-field
							v-model="search.form.search"
							outlined
							clearable
							hide-details
							label="Product"
							placeholder="product name, description, code, serial"
						/>
						<v-select
							v-model="search.form.mainCategory"
							:items="$productCategories.data.filter((v) => v.isMain)"
							outlined
							multiple
							clearable
							hide-details
							label="Category"
							item-value="id"
							item-text="name"
						/>
						<v-select
							v-model="search.form.status"
							:items="translatedStatuses"
							outlined
							multiple
							clearable
							hide-details
							label="Status"
							item-value="value"
							item-text="label"
						/>
						<div class="tw-flex tw-space-x-4 tw-col-span-3">
							<base-date-picker
								v-model="search.form.fromDate"
								:append-icon="false"
								clearable
								prepend-inner-icon
								placeholder="From Date"
								class="tw-flex-1"
							/>
							<base-date-picker
								v-model="search.form.toDate"
								:append-icon="false"
								clearable
								placeholder="To Date"
								class="tw-flex-1"
							/>
						</div>
					</div>
					<div
						class="tw-flex tw-justify-end tw-space-x-2 tw-items-end tw-mt-4"
					>
						<v-btn text color="primary" @click="onToggleSearch">
							<span class="tw-normal-case">Close</span>
						</v-btn>
						<v-btn text color="primary" @click="onResetSearch">
							<span class="tw-normal-case">Reset</span>
						</v-btn>
						<base-button
							:loading="search.isLoading"
							@click="onSearchProducts"
						>
							Search
						</base-button>
					</div>
				</div>
			</template>

			<!-- STATUS -->
			<template v-slot:[`item.status`]="{ item }">
				<div class="tw-w-40">
					<BaseStatusChanger
						:value="item.status"
						:items="translatedStatuses"
						:on-change="(v) => onChangeStatusChanger(item, v)"
					/>
				</div>
			</template>

			<!-- NAME -->
			<template v-slot:[`item.productName`]="{ item }">
				<div class="tw-flex tw-items-center tw-space-s-5">
					<v-img
						cover
						width="48"
						height="48"
						class="tw-rounded-md tw-flex-none"
						:src="$image($get(item, 'attachments.0.attachedLink'))"
					/>
					<div>
						<div>{{ item.name }}</div>
						<div
							class="tw-opacity-70 tw-font-medium tw-text-xs tw-truncate tw-max-w-xs"
						>
							{{ item.description }}
						</div>
					</div>
				</div>
			</template>

			<!-- DISCOUNT -->
			<template v-slot:[`item.discount`]="{ item: { offer, currency } }">
				<template v-if="offer && offer.offeringType === 'amount'">
					{{ currency.currency }}
				</template>
				{{ $get(offer, 'discount', '-') }}
				<template v-if="offer && offer.offeringType === 'percentage'">
					%
				</template>
			</template>

			<!-- PRICE -->
			<template v-slot:[`item.price`]="{ item }">
				{{ item.currency.currency }} {{ item.price }}
			</template>

			<!-- FINAL PRICE -->
			<template v-slot:[`item.finalPrice`]="{ item }">
				<template v-if="item.offer">{{ item.currency.currency }}</template>
				{{ $get(item, 'offer.finalPrice', '-') }}
			</template>
		</base-data-table>

		<v-dialog
			persistent
			max-width="1198"
			v-model="productForm.dialog"
			v-if="productForm.dialog"
			content-class="tw-rounded-2xl tw-shadow-lg"
			scrollable
		>
			<NewProductForm
				v-model="productForm.dialog"
				:error="error"
				:onView="onView"
				:onUpdate="onUpdate"
				:product="productForm.data"
				:update-function="onUpdateProduct"
				:create-function="handleCreateProduct"
				@onClose="onResetProductForm"
			/>
		</v-dialog>

		<v-dialog
			v-model="bulkDialog"
			persistent
			scrollable
			max-width="1198"
			content-class="tw-rounded-2xl tw-shadow-lg"
		>
			<v-card class="tw-px-6 tw-py-8">
				<v-card-title class="tw-text-22 tw-font-semibold tw-text-black">
					{{ $t('productImages') }}
				</v-card-title>
				<v-card-text>
					<BaseUpload
						v-model="bulkAttachments"
						class="tw-mt-11"
						title="fileToUpload"
						subtitle="max500Sheet"
						accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
					/>
				</v-card-text>
				<v-card-actions
					class="tw-mt-16 tw-mb-4 tw-flex tw-py-0 tw-space-x-7 tw-px-6"
				>
					<v-btn
						outlined
						color="primary"
						height="58"
						class="tw-flex-1 tw-rounded-lg"
						text
						@click="bulkDialog = false"
						><span
							class="tw-text-lg tw-font-medium tw-capitalize tw-text-72"
							>{{ $t('actions.cancel') }}</span
						>
					</v-btn>
					<v-btn
						height="58"
						class="tw-flex-1 tw-font-medium tw-rounded-lg"
						depressed
						type="submit"
						color="primary"
						:loading="bulkUploading"
						@click="onBulkUploadProducts"
						><span
							class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
							>{{ $t('actions.createBulkProducts') }}</span
						>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<DialogDeleteConfirmation
			v-model="confirmDialog"
			:id="onDeleteId"
			:title="$t('product')"
			:delete-function="deleteProduct"
         hideId
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { omitEmpties, toFormData } from 'vuelpers'
import { createFormMixin } from '@/mixins/form-mixin'

import NewProductForm from '../../components/forms/NewProductForm.vue'
import DialogDeleteConfirmation from '../../components/dialogs/DialogDeleteConfirmation.vue'

const initialSearchForm = () => {
	return {
		search: '',
		mainCategory: '',
		status: '',
		fromDate: '',
		toDate: '',
	}
}

export default {
	name: 'Products',
	components: {
		NewProductForm,
		DialogDeleteConfirmation,
	},
	mixins: [
		createFormMixin({
			rules: ['required', 'email', 'password'],
		}),
	],
	data: () => ({
		search: {
			isLoading: false,
			isExpanded: false,
			form: initialSearchForm(),
		},
		bulkUploading: false,
		errors: {},
		productForm: {
			data: null,
			dialog: false,
		},
		bulkDialog: false,
		date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
			.toISOString()
			.substring(0, 10),
		menu: false,
		modal: false,
		menu2: false,
		startDateMenu: false,
		endDate: null,
		startDate: null,
		statuses: [
			{ value: 'pending', label: 'status.pending' },
			{ value: 'active', label: 'status.active' },
			{ value: 'deactive', label: 'status.deactive' },
			{ value: 'out-of-stock', label: 'status.out-of-stock' },
			{ value: 'delay', label: 'status.delay' },
			{ value: 'in-store', label: 'status.in-store' },
		],
		headers: [
			{ text: 'headers.productName', value: 'productName' },
			{ text: 'headers.category', value: 'mainCategory.0.name' },
			{
				text: 'headers.price',
				value: 'price',
			},
			{
				text: 'headers.discount',
				value: 'discount',
			},
			{ text: 'headers.offerPrice', value: 'finalPrice' },
			{
				text: 'headers.quantity',
				value: 'quantity',
			},
			{ text: 'headers.status', value: 'status' },
			{ text: 'headers.actions', value: 'actions' },
		],

		error: {},
		productData: {},
		onView: false,
		onUpdate: false,
		loading: false,
		confirmDialog: false,
		onDeleteId: null,
		bulkAttachments: null,
	}),
	computed: {
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('products', ['$products']),
		...mapGetters('category', ['$productCategories']),
		...mapGetters('products', ['$colors', '$sizes', '$tags', '$currencies']),
		actions() {
			return [
				{
					text: this.$t('view-details'),
					event: 'view',
					icon: 'mdi-eye',
				},
				{
					text: this.$t('edit-information'),
					event: 'update',
					icon: 'mdi-pencil',
				},
				{
					divider: true,
				},
				{
					icon: 'mdi-checkbox-intermediate',
					event: 'status',
					text: (v) => {
						return v.status === 'active'
							? this.$t('actions.deactivate')
							: this.$t('actions.activate')
					},
				},
				{
					text: this.$t('actions.delete'),
					event: 'delete',
					icon: 'mdi-delete-outline',
					color: 'error',
				},
			]
		},
		categories() {
			return this.$productCategories.data
				.filter((cat) => cat.isMain)
				.map((cat) => {
					return {
						value: cat.id,
						label: cat.name,
					}
				})
		},
		subCategories() {
			if (!this.mProduct.mainCategoryId) return []
			let subCategories = this.$productCategories.data.filter((cat) => {
				return (
					!cat.isMain &&
					cat.mainCategoryId === this.mProduct.mainCategoryId
				)
			})
			return subCategories.map((subCat) => {
				return {
					value: subCat.id,
					label: subCat.name,
				}
			})
		},
		currencies() {
			return this.$currencies.map((currency) => ({
				value: currency.id,
				label: currency.currency,
			}))
		},
		colors() {
			return this.$colors.map((color) => ({
				value: color.id,
				label: color.name,
			}))
		},
		tags() {
			return this.$tags.map((tag) => ({
				value: tag.id,
				label: tag.name,
			}))
		},
		sizes() {
			return this.$sizes.map((size) => ({
				value: size.id,
				label: size.name,
			}))
		},
		products() {
			let data = this.$products.data.map((product) => {
				return {
					...product,
					...product.offers.reduce(
						(acc, offer) => {
							if (!offer.isSingleProductOffer) {
								acc.offers.push(offer)
							} else if (!acc.offer) {
								acc.offer = offer
							}
							return acc
						},
						{
							offer: null,
							offers: [],
						}
					),
				}
			})
			return {
				...this.$products,
				data: data,
			}
		},
		translatedStatuses() {
			return this.statuses.map((status) => ({
				...status,
				label: this.$t(status.label),
			}))
		},
		suppliers() {
			return this.$suppliers.data.map((supplier) => ({
				value: supplier.id,
				label: `${supplier.representativeName} (${supplier.company.name})`,
			}))
		},
	},

	created() {
		this.getCategories({ type: 'product' })
		this.getProductsDependency({ type: 'product' })
	},
	methods: {
		...mapActions('category', ['getCategories']),
		...mapActions('settings', ['toggleImagePreview']),
		...mapActions('category', ['setCategoryState', 'getCategories']),
		...mapActions('products', [
			'getProducts',
			'deleteProduct',
			'updateProduct',
			'createProduct',
			'bulkUploadProducts',
			'getProductCategories',
			'getProductsDependency',
		]),
		async onFetchProducts(query = {}) {
			this.search.isLoading = true
			const res = await this.getProducts({
				...query,
				filter: omitEmpties(this.search.form),
			})
			this.search.isLoading = false
			return res
		},
		onSearchProducts() {
			return this.onFetchProducts({ page: 1 })
		},
		onResetSearch() {
			this.search.form = initialSearchForm()
			this.onSearchProducts()
		},
		onToggleSearch() {
			this.search.isExpanded = !this.search.isExpanded
			if (!this.search.isExpanded) this.onResetSearch()
		},
		onBulkUpload() {
			this.bulkDialog = true
		},
		onSingleUpload() {
			this.productForm.dialog = true
		},
		onDeleteProduct(product) {
			this.confirmDialog = true
			this.onDeleteId = product.id
		},
		onViewProduct(user) {
			this.onView = true
			this.productForm.data = user
			this.productForm.dialog = true
		},
		async onBulkUploadProducts() {
			if (!this.bulkAttachments) return

			this.bulkUploading = true
			let [err] = await this.bulkUploadProducts(
				toFormData(
					{ file: this.bulkAttachments.file },
					{ convertCase: 'snake_case' }
				)
			)
			this.bulkUploading = false
			if (err) {
				return this.$toast.error(this.$t('error-bulk-product'))
			}
			this.$toast.success(this.$t('bulk-product-success'))
			this.bulkDialog = false

			this.onFetchProducts()
			this.bulkAttachments = null
		},
		onResetProductForm() {
			this.productForm.data = {}
			this.onUpdate = false
			this.onView = false
			this.error = {}
			this.productForm.dialog = false
		},
		onInitDelete(item) {
			this.remove = { id: item.id, modal: true }
		},
		onChangeStatusChanger(item, status) {
			return this.updateProduct(
				toFormData(
					{
						status,
						id: item.id,
					},
					{ _method: 'PATCH' }
				)
			)
		},
		onChangeStatus(
			item,
			status = item.status === 'active' ? 'deactive' : 'active'
		) {
			this.$root.confirmation = {
				title: `Change status of this Product`,
				message: this.$t('sure-to-change-status-of-product', [
					this._.capitalize(status.split('-').join(' ')),
				]),
				handler: async () => {
					let [err] = await this.updateProduct(
						toFormData(
							{
								status,
								id: item.id,
							},
							{ _method: 'PATCH' }
						)
					)
					if (err) {
						this.$toast.error(
							this.$t('error-status', [this.$t('product')])
						)
						return
					}
					this.$toast.success(
						this.$t('success-status', [this.$t('product')])
					)
				},
			}
		},
		async handleCreateProduct(data) {
			let [err, res] = await this.createProduct(
				toFormData(data, {}, { convertCase: 'snake_case' })
			)
			if (err) {
				this.$toast.error(this.$t('error-creating', [this.$t('product')]))
			}
			//
			else {
				this.productForm.dialog = false
				this.$toast.success(this.$t('success-create', [this.$t('product')]))
				this.onResetProductForm()
			}
			return [err, res]
		},
		async onUpdateProduct(data) {
			let [err, res] = await this.updateProduct(
				toFormData(
					data,
					{ _method: 'PATCH' },
					{ convertCase: 'snake_case' }
				)
			)
			if (err) {
				this.$toast.error(this.$t('error-updating', [this.$t('product')]))
			}
			//
			else {
				this.productForm.dialog = false
				this.$toast.success(this.$t('success-update', [this.$t('product')]))
				this.onResetProductForm()
			}
			return [err, res]
		},
		onUpdateBranch(user) {
			console.log(user)
			this.productForm.data = user
			this.productForm.dialog = true
			this.onUpdate = true
		},
	},
}
</script>
