<template>
	<div>
		<v-form
			lazy-validation
			@submit.prevent="onCreateProduct"
			:readonly="onView && !update"
			ref="form"
		>
			<v-stepper v-model="currentStep">
				<v-stepper-content :step="1" class="tw-p-0">
					<v-card
						v-if="!showImageSection"
						class="tw-overflow-x-hidden tw-overflow-y-auto tw-px-4 lg:tw-px-14 lg:tw-py-9 tw-py-6"
					>
						<v-card-text>
							<div
								class="tw-flex tw-justify-between tw-items-center tw-mb-6 lg:tw-mb-12"
							>
								<h2 class="tw-text-22 tw-font-semibold tw-text-black">
									{{ onUpdate ? $t('actions.update') : '' }}
									{{ $t('productDetails') }}
								</h2>

								<div
									v-if="!onView"
									class="tw-font-pop tw-text-base tw-text-black tw-font-normal"
								>
									{{ $t('next-step-upload-images') }}
								</div>
							</div>
							<div>
								<v-row>
									<v-col v-if="$currentUser.isAdmin" cols="12">
										<div
											class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
										>
											{{ $t('supplierLabel') }}
										</div>
										<v-autocomplete
											v-model="productData.supplierId"
											outlined
											flat
											dense
											class="user-input"
											hide-details="auto"
											:items="suppliers"
											:loading="!$suppliers.isLoaded"
											:rules="[rules.required($t('supplierLabel'))]"
											:placeholder="$t('selectSupplier')"
											:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
											:error-messages="$get(error, 'supplierId', [])"
										/>
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<div
											class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
										>
											{{ $t('productForm.productName') }}
										</div>
										<v-text-field
											outlined
											flat
											:rules="[
												rules.required(
													$t('productForm.productName')
												),
											]"
											class="user-input"
											hide-details="auto"
											v-model="productData.name"
											:placeholder="$t('productForm.productName')"
											dense
											:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
											:error-messages="
												error.phone ? error.phone[0] : []
											"
										/>
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<div
											class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
										>
											{{ $t('productForm.tags') }}
										</div>
										<v-combobox
											dense
											flat
											outlined
											multiple
											clearable
											item-value="id"
											item-text="name"
											class="user-input"
											hide-details="auto"
											:placeholder="$t('select-tags')"
											:items="$tags"
											:rules="[
												rules.required($t('productForm.tags')),
											]"
											v-model="productData.tags"
										/>
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<div
											class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
										>
											{{ $t('productCode') }}
										</div>
										<v-text-field
											outlined
											v-model="productData.productCode"
											flat
											:rules="[rules.required($t('code'))]"
											class="user-input"
											hide-details="auto"
											:placeholder="$t('productCode')"
											dense
											:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
											:error-messages="
												error.phone ? error.phone[0] : []
											"
										/>
									</v-col>
									<v-col cols="12">
										<div
											class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
										>
											{{ $t('productDescription') }}
										</div>

										<v-textarea
											outlined
											rows="2"
											flat
											:rules="[rules.required($t('description'))]"
											auto-grow
											v-model="productData.description"
											class="user-input tw-rounded-md"
											:placeholder="$t('write-product-description')"
											dense
											:error-messages="
												error.description
													? error.description[0]
													: []
											"
										></v-textarea>
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<div
											class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
										>
											{{ $t('price') }}
										</div>
										<v-text-field
											v-model="productData.price"
											:placeholder="$t('price')"
											:rules="[rules.required($t('price'))]"
											:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
											flat
											dense
											outlined
											name="price"
											type="number"
											class="user-input"
											hide-details="auto"
										/>
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<div
											class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
										>
											{{ $t('currency') }}
										</div>
										<v-autocomplete
											outlined
											:items="$currencies"
											flat
											:rules="[rules.required($t('currency'))]"
											class="user-input"
											hide-details="auto"
											v-model="productData.currencyId"
											item-value="id"
											item-text="currency"
											:placeholder="$t('select-currency')"
											dense
											:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
										/>
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<div
											class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
										>
											{{ $t('headers.serialNumber') }}
										</div>
										<v-text-field
											outlined
											flat
											:rules="[
												rules.required($t('headers.serialNumber')),
											]"
											class="user-input"
											hide-details="auto"
											v-model="productData.serialNumber"
											:placeholder="$t('headers.serialNumber')"
											dense
											:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
											:error-messages="
												error.serail ? error.serail[0] : []
											"
										/>
									</v-col>
									<v-col cols="12">
										<div class="tw-flex tw-items-center">
											<h4
												class="tw-me-10 tw-text-black tw-text-semibold"
											>
												{{ $t('enable-offer') }}
											</h4>
											<div
												class="tw-flex tw-bg-fcf tw-rounded-22px tw-px-2 tw-py-2"
											>
												<v-switch v-model="productData.isOffer">
												</v-switch>
											</div>
										</div>
									</v-col>

									<template v-if="productData.isOffer">
										<v-col cols="12" sm="4">
											<div
												class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
											>
												{{ $t('offer-type') }}
											</div>
											<v-autocomplete
												outlined
												:items="['percentage', 'amount']"
												flat
												:rules="[rules.required($t('offer-type'))]"
												class="user-input"
												hide-details="auto"
												:placeholder="$t('select-offer-type')"
												v-model="productData.offer.offeringType"
												dense
												:height="
													$vuetify.breakpoint.lgAndUp ? 60 : ''
												"
											/>
										</v-col>
										<v-col
											v-if="
												productData.offer.offeringType === 'amount'
											"
											cols="12"
											sm="4"
										>
											<div
												class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
											>
												{{ $t('discount-amount') }}
											</div>
											<v-text-field
												outlined
												flat
												:rules="[
													rules.required($t('amount')),
													rules.max(
														productData.price,
														$t('amount')
													),
												]"
												class="user-input"
												hide-details="auto"
												:placeholder="$t('amount')"
												type="number"
												dense
												v-model="productData.offer.discount"
												:height="
													$vuetify.breakpoint.lgAndUp ? 60 : ''
												"
											/>
											<div
												v-if="productData.offer.discount"
												class="tw-pt-2 tw-text-primary"
											>
												{{ $t('final-price-after-discount') }}
												{{ calcFinalPrice }}
											</div>
										</v-col>
										<v-col
											v-if="
												productData.offer.offeringType ===
												'percentage'
											"
											cols="12"
											sm="4"
										>
											<div
												class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
											>
												{{ $t('offer-percentage-amount') }}
											</div>
											<v-text-field
												outlined
												flat
												:rules="[rules.required($t('amount'))]"
												class="user-input"
												hide-details="auto"
												:placeholder="$t('amount')"
												prefix="%"
												type="number"
												dense
												v-model="productData.offer.discount"
												:height="
													$vuetify.breakpoint.lgAndUp ? 60 : ''
												"
											/>
											<div
												v-if="productData.offer.discount"
												class="tw-text-primary tw-pt-2"
											>
												{{ $t('final-price-after-discount') }}
												{{ calcFinalPrice }}
											</div>
										</v-col>
										<span></span>
										<v-col>
											<div
												class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
											>
												{{ $t('currency') }}
											</div>
											<v-autocomplete
												outlined
												:items="$currencies"
												flat
												:rules="[rules.required($t('currency'))]"
												class="user-input"
												hide-details="auto"
												v-model="productData.offer.currencyId"
												item-value="id"
												item-text="currency"
												:placeholder="$t('select-currency')"
												dense
												:height="
													$vuetify.breakpoint.lgAndUp ? 60 : ''
												"
											/>
										</v-col>
									</template>

									<v-col cols="12" sm="4">
										<div
											class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-justify-between tw-text-47"
										>
											<span>{{ $t('quantity') }}</span>
											<v-switch
												v-model="productData.isSpecifiedQuantity"
												hide-details
												class="ma-0 pa-0"
												@change="
													(v) => !v && (productData.quantity = '')
												"
											/>
										</div>
										<v-text-field
											v-model="productData.quantity"
											:placeholder="$t('enter-quantity')"
											:error-messages="error.quantity"
											:disabled="!productData.isSpecifiedQuantity"
											:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
											:rules="
												productData.isSpecifiedQuantity
													? [rules.required($t('quantity'))]
													: []
											"
											flat
											dense
											outlined
											type="number"
											name="quantity"
											class="user-input"
											hide-details="auto"
										/>
									</v-col>
									<v-col cols="12" sm="4">
										<div
											class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
										>
											{{ $t('size') }}
										</div>
										<v-autocomplete
											outlined
											flat
											:items="$sizes"
											:rules="[rules.required($t('size'))]"
											class="user-input"
											hide-details="auto"
											v-model="productData.size"
											:placeholder="$t('select-size')"
											dense
											multiple
											item-text="name"
											item-value="id"
											:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
											:error-messages="
												error.size ? error.size[0] : []
											"
										/>
									</v-col>
									<v-col cols="12" sm="4">
										<div
											class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
										>
											{{ $t('headers.color') }}
										</div>
										<v-autocomplete
											outlined
											flat
											:items="$colors"
											multiple
											item-text="name"
											item-value="id"
											:rules="[rules.required($t('headers.color'))]"
											class="user-input"
											hide-details="auto"
											v-model="productData.color"
											:placeholder="$t('select-color')"
											dense
											:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
											:error-messages="
												error.color ? error.color[0] : []
											"
										/>
									</v-col>
									<!-- <v-col cols="12">
										<div class="tw-flex tw-items-center">
											<h4
												class="tw-me-10 tw-text-black tw-text-semibold"
											>
												{{ $t('unspecifiedQuantity') }}
											</h4>
											<div
												class="tw-flex tw-bg-fcf tw-rounded-22px tw-px-2 tw-py-2"
											>
												<div class="tw-px-6 tw-py-2">
													<v-icon size="20">mdi mdi-check</v-icon>
												</div>
												<div
													class="tw-px-6 tw-py-2 tw-bg-white tw-rounded-22px tw-shadow "
												>
													<v-icon size="16" class="tw-px-6 tw-py-2"
														>mdi mdi-block-helper</v-icon
													>
												</div>
												<v-switch
													v-model="productData.isSpecifiedQuantity"
												>
												</v-switch>
											</div>
										</div>
									</v-col> -->
									<v-col cols="12" sm="4">
										<div class="tw-flex">
											<div class="tw-flex-1">
												<div
													class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47 tw-h-6"
												>
													{{ $t('select') }}
													{{ $t('headers.mainCategory') }}
												</div>
												<v-autocomplete
													outlined
													:items="categories"
													flat
													:rules="[
														rules.required($t('categories')),
													]"
													class="user-input"
													hide-details="auto"
													item-text="name"
													item-value="id"
													v-model="productData.mainCategoryId"
													:placeholder="$t('select-category')"
													dense
													:height="
														$vuetify.breakpoint.lgAndUp ? 60 : ''
													"
												/>
											</div>
											<v-btn
												v-if="!onView"
												depressed
												:height="
													$vuetify.breakpoint.lgAndUp ? 60 : ''
												"
												color="secondary"
												class="tw-mt-10"
												@click="initCategoryModal"
											>
												<v-icon>mdi-plus</v-icon>
											</v-btn>
										</div>
									</v-col>
									<v-col cols="12" sm="4">
										<div class="tw-flex">
											<div class="tw-flex-1">
												<div
													class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47 tw-h-6"
												>
													{{ $t('select') }}
													{{ $t('headers.subCategory') }}
												</div>
												<v-autocomplete
													flat
													dense
													outlined
													item-value="id"
													item-text="name"
													class="user-input"
													hide-details="auto"
													:placeholder="$t('select-category')"
													:items="subCategories"
													:rules="[
														rules.required(
															$t('headers.subCategory')
														),
													]"
													:height="
														$vuetify.breakpoint.lgAndUp ? 60 : ''
													"
													v-model="productData.subCategoryId"
												/>
											</div>
											<v-btn
												v-if="!onView"
												depressed
												:disabled="!productData.mainCategoryId"
												:height="
													$vuetify.breakpoint.lgAndUp ? 60 : ''
												"
												color="secondary"
												class="tw-mt-10"
												@click="onAddSubCategory"
											>
												<v-icon>mdi-plus</v-icon>
											</v-btn>
										</div>
									</v-col>
									<!-- {{ calcFinalPrice }} {{}} -->
									<v-col cols="12" sm="4">
										<div
											class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
										>
											{{ $t('trademark') }}
										</div>
										<v-text-field
											outlined
											flat
											:rules="[rules.required($t('trademark'))]"
											class="user-input"
											hide-details="auto"
											v-model="productData.tradeMark"
											:placeholder="$t('enter-trademark')"
											dense
											:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
											:error-messages="
												error.tradeMark ? error.tradeMark[0] : []
											"
										/>
									</v-col>

									<v-col cols="12" md="4">
										<v-checkbox
											v-model="productData.isPostedOnSite"
											hide-details="auto"
											:label="$t('posted-on-this-site')"
										/>
									</v-col>
									<v-col cols="12" md="4">
										<v-checkbox
											v-model="productData.hasTrialProduct"
											hide-details="auto"
											:label="$t('has-a-trial-product')"
										/>
									</v-col>
									<v-col
										v-if="productData.hasTrialProduct"
										cols="12"
										md="4"
									>
										<div
											class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
										>
											{{ $t('trail-product-quantity') }}
										</div>
										<v-text-field
											outlined
											flat
											type="number"
											:rules="[
												rules.required($t('quantity')),
												rules.min(0),
												rules.max(
													productData.trialProduct
														.quantityPerBuyer,
													$t('quantity')
												),
											]"
											class="user-input"
											hide-details="auto"
											v-model="
												productData.trialProduct.quantityPerBuyer
											"
											:placeholder="$t('enter-quantity')"
											dense
											:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
											:error-messages="
												error.quantityPerBuyer
													? error.quantityPerBuyer[0]
													: []
											"
										/>
									</v-col>
								</v-row>
							</div>
						</v-card-text>
						<v-card-actions
							class="tw-mt-10 lg:tw-mt-14 tw-flex tw-p-0 tw-space-x-6"
						>
							<v-btn
								text
								outlined
								height="58"
								type="button"
								color="primary"
								class="tw-flex-1 tw-rounded-lg"
								@click="onResetProductForm"
								><span
									class="tw-text-lg tw-font-medium tw-capitalize tw-text-72"
									>{{ $t('actions.cancel') }}</span
								>
							</v-btn>
							<v-btn
								depressed
								height="58"
								type="button"
								color="primary"
								@click="validateOnProceed"
								class="tw-flex-1 tw-font-medium tw-rounded-lg"
								><span
									class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
									>{{
										onView
											? $t('productImages')
											: $t('actions.proceed')
									}}</span
								>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-stepper-content>
				<v-stepper-content :step="2" class="tw-p-0">
					<v-card
						class="tw-overflow-x-hidden tw-overflow-y-auto tw-px-4 lg:tw-px-14 lg:tw-py-9 tw-py-6"
					>
						<h2
							class="tw-text-22 tw-font-semibold tw-text-black tw-mb-6 lg:tw-mb-12"
						>
							{{ $t('productImages') }}
						</h2>
						<BaseDropZone
							multiple
							:readonly="onView"
							v-model="productData.attachments"
						/>
						<v-card-actions
							class="tw-mt-10 lg:tw-mt-14 tw-flex tw-p-0 tw-space-x-6"
						>
							<v-btn
								text
								outlined
								height="58"
								color="primary"
								class="tw-flex-1 tw-rounded-lg"
								@click="currentStep = 1"
								><span
									class="tw-text-lg tw-font-medium tw-capitalize tw-text-72"
									>{{ $t('back-to-product-information') }}</span
								>
							</v-btn>
							<v-btn
								v-if="!onView"
								depressed
								height="58"
								type="submit"
								color="primary"
								class="tw-flex-1 tw-font-medium tw-rounded-lg"
								:loading="isLoading"
								:disabled="productData.attachments.length === 0"
							>
								<span
									class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
								>
									{{
										onUpdate
											? 'Update New Product'
											: 'Create New Product'
									}}
								</span>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-stepper-content>
			</v-stepper>
		</v-form>
	</div>
</template>

<script>
import { omitEmpties } from 'vuelpers'
import { mapActions, mapGetters } from 'vuex'
import { createFormMixin } from '@/mixins/form-mixin'

import UseFilePicker from '../../mixins/UseFilePicker'
import BaseDropZone from '../../components/base/BaseDropZone.vue'

export default {
	name: 'NewProductForm.vue',
	mixins: [
		UseFilePicker,
		createFormMixin({
			rules: ['required', 'email', 'password', 'max', 'min'],
		}),
	],
	components: {
		BaseDropZone,
	},
	props: {
		product: Object,
		createFunction: {
			required: true,
			type: Function,
		},
		updateFunction: {
			required: true,
			type: Function,
		},
		onView: {
			type: Boolean,
			default: false,
		},
		onUpdate: {
			type: Boolean,
			default: false,
		},
		error: Object,
	},
	watch: {
		product: {
			deep: true,
			immediate: true,
			handler(v) {
				this.productData = this.initialProduct(v || {})
				if (!this.$currentUser.isAdmin) {
					this.productData.supplierId = this.$get(
						this.$currentUser,
						'supplier.0.id'
					)
					return
				}
				if (this.$suppliers.isLoaded) return
				this.getSupplier()
			},
		},
	},
	data() {
		return {
			isLoading: false,
			productData: {},
			showBuyerAttachmentError: false,
			showUserPermissionError: false,
			menu: false,
			update: false,
			copyOfCard: null,
			branchData: {},
			finalPriceError: false,
			showImageSection: false,
			productAttachments: [],
			currentStep: 1,
		}
	},

	computed: {
		...mapGetters('suppliers', ['$suppliers']),
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('category', ['$productCategories']),
		...mapGetters('products', ['$colors', '$sizes', '$tags', '$currencies']),
		suppliers() {
			return this.$suppliers.data.map((supplier) => ({
				value: supplier.id,
				text: `${supplier.representativeName} (${supplier.company.name})`,
			}))
		},
		sizes() {
			return this.$sizes.map((size) => ({
				name: size.id,
				id: size.name,
			}))
		},
		colors() {
			return this.$colors.map((color) => ({
				value: color.id,
				label: color.name,
			}))
		},
		currencies() {
			return this.$currencies.map((currency) => ({
				value: currency.id,
				label: currency.currency,
			}))
		},
		categories() {
			return this.$productCategories.data
				.filter((cat) => cat.isMain)
				.map((cat) => {
					return {
						id: cat.id,
						name: cat.name,
					}
				})
		},
		calcFinalPrice() {
			if (
				!this.productData.isOffer ||
				!this.productData.price ||
				!this.productData.offer.discount
			)
				return 0

			if (this.productData.offer.offeringType === 'percentage') {
				return (
					(this.productData.price / 100) *
					(100 - this.productData.offer.discount)
				)
			} else {
				return this.productData.price - this.productData.offer.discount
			}
		},
		subCategories() {
			if (!this.productData.mainCategoryId) return []
			return (
				this.$productCategories.data.filter((cat) => {
					return (
						!+cat.isMain &&
						cat.mainCategoryId == this.productData.mainCategoryId
					)
				}) || []
			)
		},
		onSubmit() {
			return this.productData.id ? this.updateFunction : this.createFunction
		},
	},
	methods: {
		...mapActions('suppliers', ['getSupplier']),
		...mapActions('settings', ['toggleImagePreview']),
		...mapActions('category', ['setCategoryState', 'getCategories']),
		...mapActions('products', [
			'updateProduct',
			'createProduct',
			'getProductsDependency',
		]),
		onAddSubCategory() {
			this.initCategoryModal({
				isMain: 0,
				mainCategoryId: this.productData.mainCategoryId,
			})
		},
		initCategoryModal(config = {}) {
			this.setCategoryState({
				create: {
					modal: true,
					isLoading: false,
					data: {
						name: '',
						isMain: 1,
						type: 'product',
						...config,
					},
				},
			})
		},
		initialProduct(v = {}) {
			const offer =
				v?.offer || v?.offers?.find((offer) => offer.isSingleProductOffer)
			if (offer) {
				v.offer = offer
				v.isOffer = true
			}

			return {
				id: v?.id,
				name: v?.name,
				price: v?.price,
				tags: v?.tags ?? [],
				currencyId: v?.currencyId,
				description: v?.description,
				productCode: v?.productCode,
				serialNumber: v?.serialNumber,
				quantity: v?.quantity,
				size: v?.sizes?.map((size) => size.id) ?? [],
				color: v?.colors?.map((color) => color.id) ?? [],
				supplierId:
					v?.supplierId ||
					this.$get(v, 'supplier.0.id') ||
					this.$get(this.$currentUser, 'supplier.0.id'),
				subCategoryId: (v?.subCategory || [])[0]?.id,
				mainCategoryId: (v?.mainCategory || [])[0]?.id,
				isSpecifiedQuantity: v?.isSpecifiedQuantity ?? 1,
				isPostedOnSite: v?.isPostedOnSite ?? 0,
				hasTrialProduct: !!v.trialProduct,
				trialProduct: {
					id: v?.trialProduct?.id,
					status: v?.trialProduct?.status ?? 1,
					quantity: v?.trialProduct?.quantity ?? 1,
					quantityPerBuyer: v?.trialProduct?.quantityPerBuyer,
				},
				tradeMark: v?.tradeMark,
				attachments:
					v?.attachments?.map((attach) => ({
						file: null,
						id: attach.id,
						src: this.$image(attach.attachedLink),
					})) ?? [],
				isOffer: v?.isOffer || false,
				offer: {
					offeringType: v?.offer?.offeringType || 'percentage',
					discount: v?.offer?.discount || '',
					currencyId: v?.offer?.currencyId || '',
					finalPrice: v?.offer?.finalPrice || '',
					isSingleProductOffer: 1,
				},
			}
		},
		onResetProductForm() {
			this.$refs.form.reset()
			;(this.showBuyerAttachmentError = false),
				(this.showUserPermissionError = false),
				(this.update = false)
			this.copyOfCard = null
			console.log('reset')
			this.$emit('onClose')
			this.productData = this.initialProduct()
		},
		onRepresentativeAttachment() {
			this.openFilePicker({ multiple: false }, (image) => {
				console.log(image)

				this.copyOfCard = image
				this.showBuyerAttachmentError = false
			})
		},
		validateOnProceed() {
			if (this.onView) {
				this.currentStep = 2
				return
			}

			if (!this.$refs.form.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}

			this.currentStep = 2
		},
		async onCreateProduct() {
			if (!this.$refs.form.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}

			let product = {
				...this.productData,
				supplierId:
					this.productData.supplierId ||
					this.$get(this.productData, 'supplier.0.id') ||
					this.$get(this.$currentUser, 'supplier.0.id'),
				attachments: this.productData.attachments
					.filter((image) => !!image.file)
					.map((image) => image.file),
				previousAttachments: this.productData.attachments
					.filter((image) => !image.file)
					.map((image) => image.id),

				offer: {
					...this.productData.offer,
					finalPrice: this.calcFinalPrice,
					isSingleProductOffer: 1,
				},
				hasTrialProduct: +this.productData.hasTrialProduct,
				isPostedOnSite: +this.productData.isPostedOnSite,
				isSpecifiedQuantity: +this.productData.isSpecifiedQuantity,
				// trial: this.productData.trialProduct,
				...this.productData.tags.reduce(
					(acc, tag) => {
						if (typeof tag === 'string') acc.newTags.push(tag)
						else acc.tags.push(tag.id)
						return acc
					},
					{ tags: [], newTags: [] }
				),
			}

			product.isOffer = +product.isOffer
			product.isSingleProductOffer = 1

			if (!product.isOffer) delete product.offer
			if (!product.tradeMark) delete product.tradeMark
			if (!product.description) delete product.description
			if (!product.isSpecifiedQuantity) delete product.quantity
			if (!product.hasTrialProduct) delete product.trialProduct
			// if (!product.trialProduct.id) delete product.trialProduct.id

			// delete data.trialProduct;
			// if (data.hasTrialProduct == 0 || false) delete data.trialProduct;
			this.isLoading = true
			await this.onSubmit(
				omitEmpties(product, {
					ignore: ['hasTrialProduct', 'isPostedOnSite'],
				})
			)
			this.isLoading = false
		},
		onUpdateFromView() {
			this.update = true
			;(this.showBuyerAttachmentError = false),
				(this.showUserPermissionError = false)
		},
		onPickProductImages(e) {
			this.onFilePicked(e, { multiple: true }, (images) => {
				this.productImages.push(...images)
				this.productImageError = false
			})
		},
	},
}
</script>

<style lang="scss">
.user-input.v-text-field--outlined fieldset {
	color: #f0f0f0 !important;
}
.user-input input::placeholder {
	color: black !important;
	opacity: 0.25;
	font-size: 16px !important;
}
</style>
